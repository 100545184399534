'use strict';

var pkceModule = require('oauth-pkce');

/**
 * Callback of the pkce module that will generate
 * code verifier and code challenge for the BE to use
 *
 * @param {Object} error Error for the pkce generation
 * @param {Object} response the response object that contains the code verifier and code challenge
 * @returns {void}
 */
function pkceCallback(error, response) {
    if (error) throw error;

    if (response !== null) {
        var $form = $('.login-oauth-wivai.pkce-protected');
        var $anchor;

        if ($form.length > 0) {
            $anchor = $form.find('a.pkce-anchor').first();
            if ($anchor.length > 0) {
                window.location.href = $anchor.attr('href') + '&cv=' + response.verifier + '&cc=' + response.challenge;
            }
        }
    }
}

$(function () {
    var tfAuthLink = $('#tfAuth');

    if (tfAuthLink) {
        pkceModule.default(43, pkceCallback);
    }

    // Read auth code and state from IdP reply
    if (window.location.hash && window.location.hash !== '') {
        var $anchor = $('#idpCAReply');
        if ($anchor) {
            var authCode;
            var state;
            var queryparams = window.location.hash.split('#')[1];
            var params = queryparams.split('&');
            for (var i = 0; i < params.length; i += 1) {
                var pair = params[i].split('=');
                if (decodeURIComponent(pair[0]) === 'access_token') {
                    authCode = decodeURIComponent(pair[1]);
                }
                if (decodeURIComponent(pair[0]) === 'state') {
                    state = decodeURIComponent(pair[1]);
                }
            }
            if (!authCode || !state) {
                window.location.href = $anchor.data('error');
            } else {
                window.location.href = $anchor.attr('href') + '?code=' + authCode + '&state=' + state;
            }
        }
    }
});
